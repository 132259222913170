import React from "react";
import "../style.css";
import { uncompletedTaskList, completedTaskList } from "../tempVariables";

export const BTasks = () => {
  return (
    <div className="bb_tasks">
      <BMainContent />
    </div>
  );
};

const BMainContent = () => {
  // const tasks: Slettes når fetching fra DB er fikset
  // TODO:: legg til timestamp
  const tasksUncompleted = uncompletedTaskList; // temp
  const tasksCompleted = completedTaskList; //temp

  return (
    <div className="mainContent">
      <BTask title="Ledige oppgaver" tasks={tasksUncompleted} />
      <BTask title="Fullførte oppgaver" tasks={tasksCompleted} />
    </div>
  );
};

const BTask = ({
  title,
  tasks,
}: {
  title: string;
  tasks: { text: string; completed: boolean; completedBy?: string }[];
}) => {
  return (
    <div>
      <h3> {title} </h3>
      {/* <h3 style={{ color: "yellow" }}> {title} </h3> */}

      <ul className="taskList">
        {tasks.map((task) => {
          return (
            <li className={task.completed ? "completedTask" : "taskItem"}>
              <div>{task.text}</div>
              <div>{task.completedBy ?? ""}</div>
              {/*TODO:: Lagnavn skal være i mindre skrift og ikke streket over */}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
