import React, { ReactNode } from "react";
import "../style.css";
import { spacing } from "../Theme";
import styled from "styled-components";
import {
  barList,
  barObjects,
  infoMap,
  mapCols,
  mapRows,
  numCols,
} from "../tempVariables";

interface MapInfoProps {
  team: {
    teamId: number;
    boats: {
      large: string[];
      small: string[];
    };
  };
  hits: string[];
  misses: string[];
}

// temp data (delete after DB is fixed):
const mapInfo: MapInfoProps = infoMap;

export const BMap = () => {
  return (
    <MapDiv>
      <h1> Map</h1>
      <Map />
      <BarList />
    </MapDiv>
  );
};

const BarList = () => {
  const bars = barObjects();

  return (
    <div>
      <h2> Bars </h2>
      <table>
        {bars.map((bar) => {
          return (
            <BarTR id={"li" + bar.name}>
              <BarTD>{bar.coord}</BarTD>
              <BarTD> • </BarTD>
              <BarTD> {bar.name}</BarTD>
            </BarTR>
          );
        })}
      </table>
    </div>
  );
};

const Map = () => {
  const barAndCoords = barObjects();
  let row: JSX.Element[] = [];
  let cols: JSX.Element[][] = [];
  barAndCoords.map((barCoord, idx) => {
    const targetId = "#li" + barCoord.name;
    row.push(
      <Square isHit={barCoord.isHit} hasBoat={barCoord.containsBoat}>
        <LocationText
          onClick={scrollHandler}
          href={"#li" + barCoord.name}
          isHit={barCoord.isHit}
        >
          {barCoord.coord}
        </LocationText>
      </Square>
    );

    if ((idx + 1) % numCols === 0) {
      cols.push(row);
      row = [];
    }
  });

  return (
    <div>
      {cols.map((c) => {
        return (
          <div style={{ display: "flex" }}>
            {c.map((row) => {
              return row;
            })}
          </div>
        );
      })}
    </div>
  );
};

const rows = mapRows; // temp
const cols = mapCols; // temp

type SquareProps = {
  hasBoat: boolean;
  isHit: boolean;
  children: ReactNode;
};

// If has own boat -> white circle and change text to square color.
const Square = (props: SquareProps) => {
  // onClick -> lead down to bar name
  // Show the team's boats

  return (
    <StyledSquare
      numRows={rows.length + 1}
      isHit={props.isHit}
      hasBoat={props.hasBoat}
    >
      {props.children}
    </StyledSquare>
  );
};

const scrollHandler = (
  e: React.MouseEvent<HTMLElement>
  // targetId: string
) => {
  e.preventDefault();
  // let id = targetId;
  const target = e.target as Element;
  let id = target.id;
  let position = document.getElementById(id.slice(0, id.length - 1)); //removing extra last - (dash)
  window.location.href = "#" + id.slice(0, id.length - 1); // changing the url
  position && position.scrollIntoView({ behavior: "smooth", block: "start" }); //scrolling the page
};

const MapDiv = styled.div`
  padding: 24px;
  margin-bottom: 30vh;
  scrollbehavior: smooth;
  block: start;
`;

const LocationText = styled.a<{
  isHit: boolean;
}>`
  color: white;
  font-size: 5vmin;
  font-weight: 600;
  text-decoration: ${(props) =>
    props.isHit ? "line-through solid 2px" : "none"};
`;

const StyledSquare = styled.div<{
  numRows: number;
  isHit: boolean;
  hasBoat: boolean;
}>`
  background-color: ${(props) =>
    props.isHit ? (props.hasBoat ? "palevioletred" : "#afbdc9") : "#63829c"};
  height: ${(props) => "calc(100vw / " + props.numRows + ")"};
  width: ${(props) => "calc(100vw / " + props.numRows + ")"};
  border-radius: 2px;
  margin: 1px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const BarTR = styled.tr`
  td:nth-child(1) {
    color: #3d5c75;
    font-weight: 600;
  }
`;
const BarTD = styled.td`
  padding: 7px 2px;
`;
