import {useAuth} from "../hooks/useAuth";
import React, {useState} from "react";
import {getApiUrl} from "../util/hostname";
import {bFetch} from "../util/client";

export const Settings = () => {
  const {logout} = useAuth();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (newPassword !== confirmPassword) {
      setError('Passwordene er ikke like');
      return;
    }

    const result = await bFetch(`${getApiUrl()}/change-password`, {
      method: "POST",
      body: JSON.stringify({
        oldPassword,
        newPassword,
      }),
    });

    if (result.status === 200) {
      setError('Passord endret!');
    } else {
      setError('Wtf! Passord ikke endret?');
    }
  };

  const onLogout = async () => {
    await logout();
  };

  return (
    <div>
      <form onSubmit={onSubmit}>
        <label>
          Gammelt passord:
          <input
            type="password"
            value={oldPassword}
            onChange={(event) => setOldPassword(event.target.value)}
          />
        </label>
        <label>
          Nytt passord:
          <input
            type="password"
            value={newPassword}
            onChange={(event) => setNewPassword(event.target.value)}
          />
        </label>
        <label>
          Bekreft nytt passord:
          <input
            type="password"
            value={confirmPassword}
            onChange={(event) => setConfirmPassword(event.target.value)}
          />
        </label>
        <div>{error}</div>
        <button type="submit">Endre passord</button>
      </form>

      <button onClick={onLogout}>Logg ut</button>
    </div>
  );
}
