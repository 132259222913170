/*
Returns the API URL including slash at the end
 */
export const getApiUrl = () => {
  const hostname = window.location.hostname;
  if (hostname === 'localhost') {
    return 'http://localhost:3000';
  }
  return `https://${hostname.replace("live", "api")}`;
}
