// ------------------------------------
// -------------- STATUS --------------
// ------------------------------------
export const teamStatus = {
  teamName: "Tubbies",
  hp: 0,
  numHits: 2,
  numMissed: 3,
};

// ------------------------------------
// -------------- TASKS ---------------
// ------------------------------------
export const uncompletedTaskList = [
  { text: "Styrt en øl", completed: false },
  {
    text: "Få en annen gjest til å synge en tidligere Eurovision-vinner",
    completed: false,
  },
  { text: "Gjenskap et bilde fra utestedet", completed: false },
  {
    text: "Dokumenter at dere har det hyggelig i minst 5 minutter",
    completed: false,
  },
]; // ---- tasks

export const completedTaskList = [
  {
    text: "Finn en svenske og påstå hardnakket at IKEA er norsk",
    completed: true,
    completedBy: "Dream-team",
  },
  {
    text: "Ta et bilde av et lagmedlem sammen med en hund",
    completed: true,
    completedBy: "The Lulus",
  },
  {
    text: "Film at dere synger en sang som relaterer til utestedet dere er på",
    completed: true,
    completedBy: "Skanks from different mams",
  },
]; // ---- tasks

// ------------------------------------
// --------------- MAP ----------------
// ------------------------------------

export const mapRows = [1, 2, 3, 4, 5];
export const mapCols = ["A", "B", "C", "D", "E"];

export const numRows = mapRows.length;
export const numCols = mapCols.length;

export const infoMap = {
  team: {
    teamId: 0,
    boats: {
      large: ["1a", "2a", "3a"],
      small: ["4e", "4f"],
    },
  },
  hits: ["2a", "4b", "1e"],
  misses: ["0d", "6e", "2c"],
};

export const barList = [
  "Amundsen",
  "Asylet",
  "Bar Boca",
  "Bar Rabalder",
  "Blå",
  "Ingensteds",
  "Bortenfor",
  "Brewdog",
  "Jæger",
  "Oslo Camping",
  "Kulturhuset",
  "Dattera til Hagen",
  "Godthåb",
  "Kafeteria August",
  "Becco",
  "Revolver",
  "Terretoriet",
  "Oslovelo",
  "Tijuana",
  "Oslo Mek",
  "Schous mikrobryggeri",
  "Glød",
  "Glør",
  "Ingensteds",
  "Raadhuset",
];

const containsBoat = [
  false,
  false,
  false,
  false,
  false,
  false,
  true,
  true,
  false,
  false,
  false,
  false,
  false,
  true,
  false,
  false,
  false,
  false,
  true,
  false,
  false,
  false,
  false,
  true,
  false,
];

const isHit = [
  false,
  false,
  false,
  false,
  true,
  false,
  true,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  true,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
];

/**
 * @returns objects that presumably are the same as the ones we get
 * from the DB.
 */
export const barObjects = () => {
  let bars: {
    name: string;
    coord: string;
    isHit: boolean;
    containsBoat: boolean;
  }[] = [];
  let idx = 0;
  mapCols.map((col) => {
    mapRows.map((row) => {
      const barObj = {
        name: barList[idx],
        coord: col + row,
        isHit: isHit[idx],
        containsBoat: containsBoat[idx],
      };
      bars.push(barObj);
      idx++;
    });
  });
  return bars;
};
